html,
body,
#root {
    height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
}

.leaflet-popup-content p {
    margin: unset !important;
}
